/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { track } from '@amplitude/analytics-browser'
import { useToast } from '@builddocs/system'
import * as Sentry from '@sentry/react'

import useResetAll from '../../../useReset'
import { useRouter } from '../../../utils/useRouter'
import { logout } from '../auth.service'

import { TokenManipulate } from './useToken'

type Props = {
  loginUrl: string
  onLogout?: () => void
}

export const useLogOut = ({ loginUrl, onLogout }: Props) => {
  const { navigate } = useRouter()
  const { toast } = useToast()

  const resetAll = useResetAll()

  const signOut = async () => {
    try {
      // await logout()
      track('user_logout')
      localStorage.removeItem('projects-view-mode')
      navigate(loginUrl, { replace: true, state: null })
      resetAll()
      onLogout && onLogout()
      Sentry.configureScope(scope => scope.setUser(null))
      window.sessionStorage.clear()
      window.localStorage.clear()
      TokenManipulate.remove()
    } catch (e) {
      toast.warning.exception(e, 'auth.message.logout_failure')
    }
  }

  return { signOut }
}
