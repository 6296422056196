/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/**
 * Утилита для типизации пути к атрибутам объекта в виде строки.
 *
 * Например, "user.employment.employmentId"
 */

// слишком сильно нагружает tsc, а смысла не имеет.
export type PropertyStringPath<T, Prefix = ''> = any & T & Prefix
