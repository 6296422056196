import React, { memo } from 'react'

import {
  User,
  CommentItem,
  getShortName,
} from '@builddocs/data-management'
import { Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { format, formatDistanceToNow } from 'date-fns'
import { ru } from 'date-fns/locale'

type OwnProps = {
  user: User
  comment: CommentItem
}

export const UserCardInner = ({ user, comment }: OwnProps) => {
  const [avatar] = React.useState<string>('')

  // React.useEffect(() => {
  //   getAvatarSource(user.userId).then(avatar => setAvatar(avatar))
  // }, [])

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar src={avatar} sx={{ width: 32, height: 32 }} variant="rounded" />
      <Stack>
        <Typography variant="subtitle2">{getShortName(user)}</Typography>
        <Tooltip
          placement="top"
          title={format(new Date(comment.createDateTime), 'PPpp', {
            locale: ru,
          })}
        >
          <Typography color="text.secondary" variant="body2">
            {formatDistanceToNow(new Date(comment.createDateTime), {
              locale: ru,
              addSuffix: true,
            })}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export const UserCardInCommentCard = memo(UserCardInner)
