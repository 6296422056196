import { useMemo } from 'react'

export function useIsIframe() {
  const isIframe = useMemo(() => {
    try {
      console.error(`top \|/ ${window.top} ||| parent \|/ ${window.parent}`)
      return window.top !== window.parent
    } catch (e) {
      return true
    }
  }, [window])

  return isIframe
}
