/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { User, createCommentToSectionFour } from '@builddocs/data-management'
import { AxiosError } from 'axios'

import { DocumentSigningTask } from '../stamp'

type DeviateArgs = {
  statusStamp: DocumentSigningTask
  contractId: string
  user?: User
  body: any
  additionalInfo: any
  onSuccess: (msg: string) => void
  onFailure: (err: AxiosError) => void
  refresh: () => void
  refreshComments?: () => void
}

export const logToSectionFourForStamps = async (args: DeviateArgs) => {
  const {
    statusStamp,
    contractId,
    body,
    additionalInfo,
    onSuccess,
    onFailure,
    refresh,
    refreshComments,
  } = args

  try {
    await createCommentToSectionFour(contractId, {
      ...body,
      externalEntityId: statusStamp.document?.documentId,
      entityId: additionalInfo.contractId,
      entityType: 'ACT_DOC',
      externalEntityInfo: statusStamp.document?.documentType,
    })
    refresh()
    refreshComments && refreshComments()
    onSuccess(`Сделана запись в журнал замечаний для штампа`)
  } catch (err) {
    onFailure(err as AxiosError)
    //  Сохранил, чтобы не потерять сообщение, оно должно быть прописано в
    //  коде ошибки
    //  toast.error('Не удалось сделать запись в журнал замечаний')
  }
}
