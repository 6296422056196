/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export interface OrganizationBase {
  /** ИНН или ИНН/КПП*/
  inn: string
  /** телефон */
  phone?: string
  /** номер сро на строительство*/
  sro?: string | null
  /** дата выдачи сро на строительство*/
  sroDate?: string | null
  /** номер сро на проектирование*/
  sroEngineering?: string | null
  /** дата выдачи сро на проектирование*/
  sroEngineeringDate?: string | null
}

export type PhysicalAddress = {
  addressId?: string
  area?: string
  city?: string
  country?: string
  countryIsoCode?: string
  createDateTime?: string
  house?: string
  office?: string
  postalCode?: string
  region?: string
  street?: string
  unrestrictedValue?: string
}

export interface Organization extends OrganizationBase {
  /** Название организации */
  name?: string
  ogrn?: string
  /** дата выдачи огрн */
  ogrnDate?: string
  /** орг выдавшая сро на строительство*/
  sroIssuerInn?: string | null
  sroIssuerName?: string | null
  sroIssuerOgrn?: string | null
  /** орг выдавшая сро на проектирование*/
  sroEngineeringName?: string | null
  sroEngineeringOgrn?: string | null
  sroEngineeringInn?: string | null

  /** юр. адрес компании (сейчас как почтовые реквизиты в figma) */
  legalAddress?: any
  /** физический адрес компании (вводится вручную пользователем) */
  physicalAddress?: PhysicalAddress
  /** дата создания конкретной организации в системе */
  createDateTime?: string
  apiToken?: string
  integrations?: Array<string>
}

export type ResponseOrgFromProject = {
  data: {
    address: {
      data: {
        area: string
        area_fias_id: string
        area_kladr_id: string
        area_type: string
        area_type_full: string
        area_with_type: string
        beltway_distance: string
        beltway_hit: string // 'IN_KAD'
        block: string // 'ат'
        block_type: string // 'литер'
        block_type_full: string // 'литер'
        capital_marker: string // '0'
        city: string // город 'Санкт-Петербург'
        city_area: string
        city_district: string // район 'Адмиралтейский'
        city_district_fias_id: string
        city_district_kladr_id: string
        city_district_type: string // 'р-н'
        city_district_type_full: string // 'район'
        city_district_with_type: string //  район 'Адмиралтейский р-н'
        city_fias_id: string // 'c2deb16a-0330-4f05-821f-1d09c93331e6'
        city_kladr_id: string // '7800000000000'
        city_type: string // 'г'
        city_type_full: string // 'город'
        city_with_type: string // 'г Санкт-Петербург'
        country: string // страна  'Россия'
        country_iso_code: string // языковая зона 'RU'
        entrance: string
        federal_district: string // федеральный округ  'Северо-Западный'
        fias_actuality_state: string // '0'
        fias_code: string // '78000000000000009470000'
        fias_id: string // '51d261e7-f8d4-43c8-97d2-9fb758176388'
        fias_level: string // '7'
        flat: string // '64'
        flat_area: string
        flat_cadnum: string
        flat_fias_id: string
        flat_price: string
        flat_type: string // 'пом'
        flat_type_full: string // 'помещение'
        floor: string
        geo_lat: string // '59.9121983'
        geo_lon: string // '30.3292429'
        geoname_id: string // '498817'
        history_values: string
        house: string // '2'
        house_cadnum: string
        house_fias_id: string
        house_kladr_id: string
        house_type: string // 'д'
        house_type_full: string // 'дом'
        kladr_id: string // '78000000000094700'
        metro: string
        okato: string // '40262566000'
        oktmo: string // '40306000'
        postal_box: string
        postal_code: string // почтовый индекс '198095'
        qc: string // '1'
        qc_complete: string
        qc_geo: string //  '2'
        qc_house: string
        region: string // 'Санкт-Петербург'
        region_fias_id: string // региональный айди 'c2deb16a-0330-4f05-821f-1d09c93331e6'
        region_iso_code: string // 'RU-SPE'
        region_kladr_id: string //  '7800000000000'
        region_type: string // 'г'
        region_type_full: string // 'город'
        region_with_type: string // 'г Санкт-Петербург'
        settlement: string
        settlement_fias_id: string
        settlement_kladr_id: string
        settlement_type: string
        settlement_type_full: string
        settlement_with_type: string
        source: string // '190020, Г. САНКТ-ПЕТЕРБУРГ, НАБ. ОБВОДНОГО КАНАЛА, Д. 138, К. 2 ЛИТЕРА АТ, ПОМЕЩЕНИЕ 64Н-1 КОМ. №430'
        square_meter_price: string
        street: string // 'Обводного канала'
        street_fias_id: string // '51d261e7-f8d4-43c8-97d2-9fb758176388'
        street_kladr_id: string // '78000000000094700'
        street_type: string // 'наб'
        street_type_full: string // 'набережная'
        street_with_type: string // 'наб Обводного канала'
        tax_office: string // '7838'
        tax_office_legal: string //  '7838'
        timezone: string //  'UTC+3'
        unparsed_parts: string
      }
      unrestricted_value: string // адрес "190020, Г. САНКТ-ПЕТЕРБУРГ, НАБ. ОБВОДНОГО КАНАЛА, Д. 138, К. 2 ЛИТЕРА АТ, ПОМЕЩЕНИЕ 64Н-1 КОМ. №430"
      value: string // адрес "190020, Г. САНКТ-ПЕТЕРБУРГ, НАБ. ОБВОДНОГО КАНАЛА, Д. 138, К. 2 ЛИТЕРА АТ, ПОМЕЩЕНИЕ 64Н-1 КОМ. №430"
    }
    authorities: string
    branch_count: number
    branch_type: string
    capital: string
    documents: string
    emails: string
    employee_count: string
    finance: {
      debt: string
      expense: string
      income: string
      penalty: string
      tax_system: string
      year: string
    }

    founders: string
    hid: string
    inn: string
    kpp: string
    licenses: string
    management: {
      disqualified: string
      name: string // ФИО руководителя
      post: string // должность
    }

    managers: string
    name: {
      full: string // полное название АДАПТ
      full_with_opf: string // полное название с формой 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АДАПТ"'
      latin: string // название на латинице
      short: string // сокращенное название АДАПТ
      short_with_opf: string // сокращенное название с формой 'ООО "АДАПТ"'
    }

    ogrn: string
    ogrn_date: number
    okato: string
    okfs: string
    okogu: string
    okpo: string
    oktmo: string
    okved: string
    okved_type: string
    okveds: string
    opf: {
      code: string
      full: string // полное название формы 'Общество с ограниченной ответственностью'
      short: string // полное название формы 'ООО'
      type: string // тип '2014'
    }
    phones: string
    predecessors: string
    qc: string
    source: string
    state: {
      actuality_date: number
      code: string
      liquidation_date: string
      registration_date: number
      status: string // статус 'ACTIVE'
    }

    successors: string
    type: string // тип 'LEGAL'
  }
  unrestricted_value: string // сокращенное название с формой 'ООО "АДАПТ"'
  value: string // сокращенное название с формой 'ООО "АДАПТ"'
}
