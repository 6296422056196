import React from 'react'

import Select, { SelectProps } from '@mui/material/Select'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(Select)(() => ({
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  textAlign: 'left',
  textUnderlinePosition: 'fromFont',
  textDecorationSkipInk: 'none',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'solid #BCBFC0 1px',
    borderRadius: 4,
  },
}))

const BuildDocsSelect = ({ sx, ...rest }: SelectProps) => {
  return (
    <StyledSelect
      size="small"
      sx={{
        ...sx,
      }}
      {...rest}
    />
  )
}

export default React.memo(BuildDocsSelect)
