/**
 * Очистка кэша браузера и перезагрузка страницы при необходимости
 * @param {boolean} reloadPage по умолчанию true
 * @returns {void} void
 */
export const clearBrowserCache = async (reloadPage = true) => {
  caches
    ?.keys()
    .then(names => Promise.all(names.map(name => caches.delete(name))))
    .finally(() => reloadPage && window.location.reload())
}
