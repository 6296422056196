import { TypeOfSros } from '../../constants'
import { Organization, UUID } from '../../models'
import { RestAPI } from '../RestAPI'

/** Получение организации по ИНН */
export const getOrganization = (inn: string) =>
  RestAPI.get(`/organization/${inn}`).then(res => res.data || {})

/** Получение договора по ID */
export const getContract = (contractId: string) =>
  RestAPI.get(`/contracts/byjournalid/${contractId}`).then(
    res => res.data || {},
  )

export const getAssigneesContract = (contractId: string) =>
  RestAPI.get(`/contracts/signers/${contractId}`).then(res => res.data || {})

/** Получение уполномоченных из проекта по ID проекта */
export const getAssigneesProject = (projectId: UUID) =>
  RestAPI.get(`/projects/assignees?projectId=${projectId}`).then(
    res => res.data || {},
  )

export const getOrganizationsProject = (projectId: UUID, contractId: UUID) =>
  RestAPI.get(
    `/projects/${projectId}/organizations?journalId=${contractId}`,
  ).then(res => res.data || {})

/**
 * @GET
 * Создать организацию
 * @returns {any}
 */
export const createOrganization = (organization: Organization) =>
  RestAPI.post('/organization', organization)
export const updateOrganizationIntegrations = (
  organization: Organization,
  data: string[],
) => RestAPI.put(`/organization/${organization.inn}/integrations`, data)

/**
 * @GET
 * Обновить данные организации
 * @returns {any}
 */
export const updateOrganization = (organization: Organization) =>
  RestAPI.put('/organization', organization)

/**
 * @GET
 * Получение организации, которой владеет юзер
 * @returns {any}
 */
export const getOrganizationForUser = () =>
  RestAPI.get('/organization').then(res => res.data || {})

export const getAll = (innList: string[]) =>
  RestAPI.get(`/organization/all?innList=${innList.join(',')}`).then(
    res => res.data || [],
  )

/**
 * Получение юзеров из организации
 * @returns {any}
 */
export const getUsersFromOrganization = () =>
  RestAPI.get('/users/all').then(res => res.data || [])

export const check = (inn: string | number) =>
  RestAPI.get(`/organization/isexist/${inn}`).then(res => res.status === 200)

/**
 * поиск юзера по номеру телефона
 * @returns {any}
 */
export const searchUser = (phone: string, inn?: number | string) => {
  const parsedPhone = `+${phone.replace(/[^0-9]/g, '')}`

  return RestAPI.get(`/users/byphone/${parsedPhone}/${inn || ''}`)
}

export const searchUserByEmail = (email: string, inn?: number | string) => {
  return RestAPI.get(`/users/byuser/${email}/${inn || ''}`)
}

/**
 * Удаление одного отдельного вида СРО из организации
 */
export const deleteSroOrganization = (sroType: TypeOfSros) =>
  RestAPI.delete(`/organization/deleteSro/${sroType}`)
