/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useQuery } from 'react-query'

import { getCatalogOfIntegrations } from './integrations-config.service'

interface UseIntegrationsCatalogOptions {
  organizationAvailable?: boolean
  projectAvailable?: boolean
}

export const useIntegrationsCatalog = (
  opts?: UseIntegrationsCatalogOptions,
) => {
  const { organizationAvailable, projectAvailable } = opts || {}

  const { data, isLoading } = useQuery(
    ['catalogOfIntegrations', organizationAvailable, projectAvailable],
    async () =>
      await getCatalogOfIntegrations({
        organizationAvailable,
        projectAvailable,
      }),
  )

  const catalogOfIntegrationsFlat = data?.map(item => item.integration)

  return {
    catalogOfIntegrations: data,
    catalogOfIntegrationsFlat,
    isLoading,
  }
}

export default useIntegrationsCatalog
