import { createContext, useEffect, useState } from 'react'

export const FeatureFlagContext = createContext({ BUILDDOCS1941: true })

export function FeatureFlagProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [featureFlags, setFeatureFlags] = useState({ BUILDDOCS1941: true })

  useEffect(() => {
    // @ts-expect-error добавляем
    window.toggleBuilddocs1941 = () => {
      setFeatureFlags(val => ({
        BUILDDOCS1941: !val.BUILDDOCS1941,
      }))
    }

    // @ts-expect-error удаляем
    return () => (window.toggleBuilddocs1941 = undefined)
  }, [])

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
