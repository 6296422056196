/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { ElementType } from 'react'

import { alpha, styled } from '@mui/material/styles'
import Tab, { TabProps } from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'

export const StyledTabs = styled((props: TabsProps) => <Tabs {...props} />)(
  () => ({
    minHeight: 32,
    '& .MuiTabs-indicator': {
      display: 'none',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: 32,
    },
  }),
)

interface StyledTab extends TabProps {
  component: ElementType
  to: string
}

export const StyledTabSmall: React.FC<StyledTab> = styled(
  (props: StyledTab) => <Tab disableRipple={false} {...props} />,
)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.body1.fontSize,
  marginRight: theme.spacing(1),
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 6,
  paddingBottom: 8,
  minHeight: 32,
  borderRadius: Number(theme.shape.borderRadius),

  color: theme.palette.grey[700],
  '& .MuiButtonBase': {
    height: 32,
  },
  '&.Mui-selected': {
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.common.white,

    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: alpha(
      theme.palette.grey[100],
      theme.palette.action.selectedOpacity,
    ),
  },
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.grey[900],
      theme.palette.action.hoverOpacity,
    ),
  },
}))
