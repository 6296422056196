const hostname = window.location.hostname;

let fileStoreBase;
// TODO: прибраться тут, когда начнем иньектить переменные окружения
if (hostname.includes('dev.builddocs.online')) {
  fileStoreBase = `https://files-dev.builddocs.online/filestore`;
} else if (hostname.includes('dev.builddocs.team')) {
  fileStoreBase = `https://${hostname}/filestore`;
} else if (hostname.includes('prod.builddocs.team')) {
  fileStoreBase = `https://${hostname}/filestore`;
} else if (hostname === 'localhost') {
  fileStoreBase = `https://files-dev.builddocs.online/filestore`;
} else if (hostname === 'builddocs.online') {
  fileStoreBase = `https://builddocs.online/filestore`;
} else {
  fileStoreBase = `https://files.builddocs.online/filestore`;
}

export const FILE_STORE_BASE = fileStoreBase;
export const FILE_STORE_API = `${FILE_STORE_BASE}/api/v1`;

export const ADAPT_BASE = `https://${hostname}`;
export const ADAPT_API = `${ADAPT_BASE}/api`;
