import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  email,
  getFullNameReversed,
  useContract,
  useCoordinator,
  useCurrentUser,
  User,
  useUsersProfiles,
  UUID,
} from '@builddocs/data-management'
import {
  ActFormKeys,
  DocumentSign,
  extSignSend,
} from '@builddocs/filestore-api'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const StyledTableRow = styled(TableRow)(() => ({
  '& td, & th': {
    border: 0,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  ':hover': {
    cursor: 'pointer',
  },
}))

const TableCellStyled = styled(TableCell)(() => ({
  paddingLeft: 24,
  paddingRight: 24,
}))

interface IFormFields {
  email: Record<string, string>
}

type EmailsItem = {
  userId: string
  email: string
  lastName: string
  firstName: string
  middleName: string
}

type OwnProps = {
  isOpen: boolean
  onClose: () => void
  assignees: DocumentSign[]

  signOpts: {
    signType: 'ACT'
    actId: string
    actType: ActFormKeys
    documentId?: unknown
  }
  forceContractId?: UUID
}

export const InvitationDialog = ({
  isOpen,
  onClose,
  assignees,
  signOpts,
  forceContractId,
}: OwnProps) => {
  const [recipient, setRecipient] = useState<EmailsItem>()
  const [searchValue, setSearchValue] = useState('')

  const { contractId } = useCoordinator() as { contractId: string }

  const { contract } = useContract(forceContractId || contractId)

  const { usersProfiles } = useUsersProfiles(forceContractId || contractId)

  const { user } = useCurrentUser()

  const emailsArray = useMemo(() => {
    const recipients: User[] = assignees
      .map(item => usersProfiles[item.employmentId])
      .filter(Boolean)
      .filter(item => item?.userId !== user?.userId)

    return recipients.map(assignee => {
      return {
        userId: assignee?.userId,
        email: assignee.email,
        lastName: assignee.lastName,
        firstName: assignee.firstName,
        middleName: assignee.middleName ?? '',
      }
    })
  }, [assignees, user?.userId, usersProfiles])

  const [emails, setEmails] = useState<Array<EmailsItem>>(emailsArray)
  const [filteredEmailsArray, setFilteredEmailsArray] = useState(emails ?? [])

  const { control, errors, clearErrors, trigger } = useForm<IFormFields>()

  const onSendButtonClick = () => {
    if (recipient) {
      extSignSend({
        signType: signOpts.signType,
        senderUserId: user?.userId,
        externalUserId: recipient?.userId,
        journalDescription: contract?.description,
        actId: signOpts.actId,
        actType: signOpts.actType,
        externalUserEmail: recipient.email,
        journalId: forceContractId || contractId,
      })
        .then(() => toast.success(`Успешно отправлено ${recipient.email}`))
        .catch(() => toast.error(`Не удалось отправить`))
      handleClose()
    }
  }

  const onSelectRow = (assignee: EmailsItem) => {
    clearErrors()
    if (searchValue && recipient && assignee.email !== recipient.email) {
      !filteredEmailsArray[0].email.includes(searchValue) &&
        setFilteredEmailsArray(prev => {
          const newArray = [...prev]

          newArray.shift()
          return [...newArray]
        })
    }
    assignee.email === recipient?.email
      ? setRecipient(undefined)
      : setRecipient(assignee)

    trigger(`email.${assignee?.userId}`)
  }

  const handleClose = () => {
    clearErrors()
    onClose()
  }

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value)
      value
        ? setFilteredEmailsArray(
            recipient
              ? [
                  recipient,
                  ...emailsArray
                    .filter(item => item.email.includes(value))
                    .filter(item => item?.userId !== recipient?.userId),
                ]
              : [...emails.filter(item => item.email.includes(value))],
          )
        : setFilteredEmailsArray(emails)
    },
    [emails, emailsArray, recipient],
  )

  const setEmailsHandler = (value: string, assignee: EmailsItem) => {
    setEmails(prev => [
      ...prev.map(item =>
        item?.userId === assignee?.userId ? { ...item, email: value } : item,
      ),
    ])
  }

  useEffect(() => {
    setFilteredEmailsArray(emails)
    handleSearch(searchValue)
  }, [emails, handleSearch, searchValue])

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>Отправить на проверку по электронной почте</DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Divider />
        <Stack
          alignItems="flex-end"
          direction="row"
          justifyContent="space-between"
          pb={2}
          pt={2}
          px={2}
        >
          <Stack direction="row" ml="auto">
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              id="search-material"
              placeholder="Электронная почта"
              size="small"
              value={searchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleSearch(event.target.value)
              }
            />
            <IconButton onClick={() => handleSearch('')}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />

        <Table>
          <TableHead>
            <TableRow>
              <TableCellStyled>Ф.И.О</TableCellStyled>
              <TableCellStyled>ЭЛЕКТРОННАЯ ПОЧТА</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEmailsArray.map((assignee: EmailsItem) => (
              <StyledTableRow
                key={assignee?.userId}
                hover
                selected={assignee?.userId === recipient?.userId}
                onClick={() => onSelectRow(assignee)}
              >
                <TableCellStyled>
                  {getFullNameReversed(assignee)}
                </TableCellStyled>
                <TableCellStyled>
                  <Controller
                    control={control}
                    defaultValue={assignee.email}
                    name={`email.${assignee?.userId}`}
                    render={({ onChange, ...rest }) => (
                      <TextField
                        fullWidth
                        // disabled={!!recipient}
                        error={
                          !!(
                            errors?.email &&
                            errors?.email[assignee?.userId]?.message &&
                            recipient?.userId === assignee?.userId
                          )
                        }
                        helperText={
                          errors?.email &&
                          errors?.email[assignee?.userId]?.message
                        }
                        label="Электронная почта"
                        size="small"
                        onChange={event => {
                          onChange(event.target.value)
                          setEmailsHandler(event.target.value, assignee)
                          trigger(`email.${assignee?.userId}`)
                        }}
                        onClick={e => {
                          e.stopPropagation()
                        }}
                        {...rest}
                      />
                    )}
                    rules={{
                      validate: email,
                    }}
                  />
                </TableCellStyled>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography color="text.secondary" variant="caption">
            {recipient?.email}
          </Typography>
          <Button
            disabled={!recipient || !!errors.email}
            variant="contained"
            onClick={onSendButtonClick}
          >
            Отправить
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
