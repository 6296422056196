/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import qs from 'qs'

import { RestAPI } from '../RestAPI'

interface GetCatalogOptions {
  organizationAvailable?: boolean
  projectAvailable?: boolean
}

export const getCatalogOfIntegrations = (opts?: GetCatalogOptions) => {
  const { organizationAvailable, projectAvailable } = opts || {}

  return RestAPI.get('/api-key/integrations', {
    params: {
      organizationAvailable,
      projectAvailable,
    },
  }).then(res => res.data)
}

type ConfigOfProjectProps = {
  projectId: string
  integrationsList: string[] | null
}
export const setConfigOfProjectIntegrations = ({
  projectId,
  integrationsList,
}: ConfigOfProjectProps) =>
  RestAPI.post(`/api-key/integrations`, integrationsList, {
    params: {
      projectId,
    },
    paramsSerializer: p => {
      return qs.stringify(p, { arrayFormat: 'comma' })
    },
  })
