/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  ApprovalState,
  InspectionState,
  SignatureState,
  User,
  commentsLogService,
  ActForm,
} from '@builddocs/data-management'
import {
  AOSRType,
  inspectAct,
  inspectSecondaryAct,
  SecondaryActType,
  signAct,
  signSecondaryAct,
} from '@builddocs/filestore-api'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

type DeviateArgs =
  | {
      act: AOSRType | SecondaryActType
      user: User
      contractId: string
      body: any
      additionalInfo: any
      onSuccess: () => void
      refreshActs?: () => void
      refreshComments?: () => void
      faceControl?: unknown
      action: 'inspection'
      actType: keyof typeof ActForm
    }
  | {
      act: AOSRType | SecondaryActType
      user: User
      contractId: string
      body: any
      additionalInfo: any
      onSuccess: () => void
      refreshActs?: () => void
      refreshComments?: () => void
      faceControl: any
      action: 'signing'
      actType: keyof typeof ActForm
    }

export const handleDeviate = async (args: DeviateArgs) => {
  const {
    act,
    contractId,
    body,
    user,
    additionalInfo,
    onSuccess,
    refreshActs,
    actType,
    refreshComments,
  } = args

  try {
    if (args.action === 'signing') {
      if (actType === ActForm.AOSR) {
        await signAct({
          documentId: act.id,
          employmentId: user.employment.employmentId,
          signatureState: SignatureState.REJECTED,
        })
      } else {
        await signSecondaryAct({
          comment: body.problemDescription,
          documentId: act.id,
          employmentId: user.employment.employmentId,
          signatureState: SignatureState.REJECTED,
        })
      }
      await commentsLogService.create(contractId, {
        ...body,
        externalEntityId: act.id,
        externalEntityInfo: act.actType,
        entityId: additionalInfo.contractId,
        entityType: 'ACT_DOC',
      })
      toast.info(
        `Сделана запись в журнал замечаний для ${
          act.closingName ?? act.name ?? 'акта'
        }`,
      )
      onSuccess()

      refreshActs && refreshActs()
    } else {
      if (
        act.approvalStatus === ApprovalState.APPROVAL ||
        act.approvalStatus === ApprovalState.REJECTED
      ) {
        if (actType === ActForm.AOSR) {
          await inspectAct(
            act.id,
            user.employment.employmentId,
            InspectionState.REJECTED,
          )
        } else {
          await inspectSecondaryAct(
            act.id,
            user.employment.employmentId,
            InspectionState.REJECTED,
          )
        }
      }
      await commentsLogService.create(contractId, {
        ...body,
        externalEntityId: act.id,
        externalEntityInfo: act.actType,
        entityId: additionalInfo.contractId,
        entityType: 'ACT_DOC',
        problemDescription: body.problemDescription,
      })
      refreshActs && refreshActs()
      refreshComments && refreshComments()
      toast.info(`Сделана запись в журнал замечаний`)
      onSuccess()
    }
  } catch (err) {
    const e = err as AxiosError;
    if (
      e.response?.data?.message === 'Акт уже был согласован с Вашей стороны' &&
      body.problemDescription.length
    ) {
      await commentsLogService.create(contractId, {
        ...body,
        externalEntityId: act.id,
        externalEntityInfo: act.actType,
        entityId: additionalInfo.contractId,
        entityType: 'ACT_DOC',
        problemDescription: body.problemDescription,
      })
      onSuccess()
    } else toast.error('Не удалось отклонить запись')
  }
}
