import { Button } from '../../atoms'
import { ButtonProps } from '@mui/material'

export const ButtonTabsItem = ({children, value, sx, ...rest}: ButtonProps) => {
  return (
    <Button value={value} sx={{borderRadius:'4px', ...sx}} {...rest}>
      {children}
    </Button>
  )
}
